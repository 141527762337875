<template>
    <Defaultlayout>
        <!-- <Defaultlayout :userprofile="userprofile"> -->
        <div class="cart-online-order-main">
            <div class="container">
                <div id="order-details-popup">
                    <div class="card-body">
                        <div class="your-order" v-if="order_details.data != ''">
                            <h2 class="section-heading" style="margin-bottom: 10px;"
                                v-if="order_details.data.items != ''">
                                Your Order</h2>
                            <div class="order-box" style="padding: 0px 0 10px;" v-if="order_details.data != ''">
                                <div class="profile-id" v-if="order_details.data.items != ''">
                                    <div v-for="(items, index) in order_details.data.items"
                                        v-bind:key="'items' + index">
                                        <div class="media" v-for="(fooditem, ind) in getAddedItems(items.item_variants)"
                                            v-bind:key="'fooditem' + ind" style="padding-bottom: 15px;">
                                            <div class="ordered-profile mr-3">
                                                <img :src="items.item_image" alt="apple">
                                            </div>
                                            <div class="media-body">
                                                <div class="row form-row">
                                                    <div class="col-sm-6 order-description">
                                                        <h5>{{ items.item_name }}</h5>
                                                        <span v-if="parseFloat(fooditem.unit_quantity) != '0'">
                                                            {{ Number(fooditem.unit_quantity).toFixed(1) }}
                                                        </span>
                                                        <span class="ml-1" v-if="fooditem.unit_name != null">
                                                            {{ fooditem.unit_name }}
                                                        </span>
                                                        <span class="ml-2" v-if="fooditem.packaging_name != null">
                                                            {{ fooditem.packaging_name }}
                                                        </span>
                                                        <br>
                                                        <span class="w-100 mb-0"
                                                            v-if="parseFloat(fooditem.min_qty) > 0 && parseFloat(fooditem.max_qty) > 0">
                                                            (Min {{ fooditem.min_qty }} - Max {{ fooditem.max_qty }})
                                                        </span>
                                                        <span class="w-100 mb-0"
                                                            v-if="parseFloat(fooditem.min_qty) > 0 && parseFloat(fooditem.max_qty) == 0">
                                                            (Min {{ fooditem.min_qty }})
                                                        </span>
                                                        <span class="w-100 mb-0"
                                                            v-if="parseFloat(fooditem.min_qty) == 0 && parseFloat(fooditem.max_qty) > 0">
                                                            (Max {{ fooditem.max_qty }})
                                                        </span>
                                                        <h6 class="w-100 mb-2 text-black"
                                                            v-if="items.combo_variants != null">
                                                            <span v-for="(combo, ind) in items.combo_variants"
                                                                v-bind:key="'combo' + ind">
                                                                {{ combo.combo_variant_name }}
                                                                <br>
                                                                <span class="ml-4"
                                                                    v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                                                                    <span v-if="parseFloat(combo.unit_quantity) != '0'">
                                                                        {{ Number(combo.unit_quantity).toFixed(1) }}
                                                                    </span>
                                                                    <span v-if="combo.unit_name != null">
                                                                        {{ combo.unit_name }}
                                                                    </span>
                                                                    <span v-if="combo.packaging_name != null">
                                                                        {{ combo.packaging_name }}
                                                                    </span>
                                                                    <br>
                                                                </span>
                                                                <div class="col-sm-12"
                                                                    v-bind:key="'combo_choices' + ind"
                                                                    :v-if="combo.choices != null && combo.choices.length > 0"
                                                                    v-for="(obj, ind) in combo.choices">
                                                                    <div>
                                                                        <span
                                                                            :style="obj.choice_prices.filter(x => x.is_added == 1).length > 0 ? '' : 'display:none'"
                                                                            class="addOnChoices">
                                                                            {{ obj.variant_choice_name }} :</span> <span
                                                                            v-bind:key="ind"
                                                                            :v-if="obj.choice_prices != null && obj.choice_prices.length > 0"
                                                                            v-for="(cp, ind) in obj.choice_prices"><span
                                                                                :style="cp.is_added == 1 ? '' : 'display:none'">{{ cp.variant_sub_choice_name
                                                                                }}<span
                                                                                    :style="obj.choice_prices.indexOf(cp) > 0 && obj.choice_prices.indexOf(cp) > (obj.choice_prices.length - 1) ? '' : 'display:none'">,</span></span></span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12" v-bind:key="'combo_addons' + ind"
                                                                    :v-if="combo.addons != null && combo.addons.length > 0"
                                                                    v-for="(obj, ind) in combo.addons">
                                                                    <div>
                                                                        <span
                                                                            :style="obj.addon_prices.filter(x => x.is_added == 1).length > 0 ? '' : 'display:none'"
                                                                            class="addOnChoices"> {{
                            obj.variant_addon_name
                        }}
                                                                            :</span> <span v-bind:key="ind"
                                                                            :v-if="obj.addon_prices != null && obj.addon_prices.length > 0"
                                                                            v-for="(cp, ind) in obj.addon_prices"><span
                                                                                :style="cp.is_added == 1 ? '' : 'display:none'">{{ cp.variant_sub_addon_name
                                                                                }}<span
                                                                                    :style="obj.addon_prices.indexOf(cp) > -1 && obj.addon_prices.indexOf(cp) < (obj.addon_prices.length - 1) ? '' : 'display:none'">,</span></span></span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <br>
                                                        </h6>
                                                    </div>
                                                    <div class="col-sm-3 order-rate text-sm-center mt-3 mt-sm-0">
                                                        <h5 class="per-price" style="float: right;">
                                                            {{ fooditem.quantity.split(".")[0] }} &#215;
                                                            <span
                                                                v-html="priceFormatter(fooditem.discounted_price)"></span>
                                                        </h5>
                                                    </div>
                                                    <div class="col-sm-3 order-rate mt-3 mt-sm-0">
                                                        <div class="my-auto ml-sm-auto" style="float: right;">
                                                            <h5 class="price">
                                                                <span
                                                                    v-html="priceFormatter(calPriceByQty(fooditem.discounted_price, fooditem.quantity))"></span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12" v-bind:key="'item_choices' + ind"
                                                        :v-if="items.item_variants != null && items.item_variants.length > 0"
                                                        v-for="(obj, ind) in items.item_variants">
                                                        <div v-bind:key="'item_addons' + ind"
                                                            :v-if="obj.choices_addons != null && obj.choices_addons.length > 0"
                                                            v-for="(addon, ind) in obj.choices_addons">
                                                            <span class="addOnChoices"> {{ addon }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment-details" v-if="order_details.data != ''" style="padding: 0px;">
                            <span v-if="order_details.data.items != ''">
                                <h2 class="section-heading">Payment</h2>
                                <ul class="payment-details-wrap list-unstyled">
                                    <li class="d-flex justify-content-between">
                                        <h5>Item Total</h5>
                                        <i class="fa fa-inr"></i>
                                        <p>
                                            <span v-html="priceFormatter(totalAmount)"></span>
                                        </p>
                                    </li>
                                    <li class="d-flex justify-content-between"
                                        v-if="order_details.data.delivery_charges != null && order_details.data.delivery_charges != 0">
                                        <h5>Delivery Charges</h5>
                                        <p>
                                            <span v-html="priceFormatter(order_details.data.delivery_charges)"></span>
                                        </p>
                                    </li>
                                    <li class="d-flex justify-content-between delivery-charge"
                                        v-if="this.order_details.total_tax != '' && (parseFloat(this.order_details.data.order_tax) + parseFloat(this.order_details.data.packaging_cost)).toFixed(2) != '0.00'">
                                        <h5 class="mb-0">Taxes & Charges
                                            <span class="tax-and-charges orderdetail-section"><b><img
                                                        src="../assets/images/svgs/infoicon.svg" /></b>
                                                <span class="title-span"
                                                    v-html="(parseFloat(this.order_details.data.packaging_cost).toFixed(2) != 0.00 ? 'Store Packaging Cost : ' + this.currency_symbol + '' + parseFloat(this.order_details.data.packaging_cost).toFixed(2) + '<br/>' : '') + (parseFloat(this.order_details.data.order_tax).toFixed(2) != 0.00 ? `${store_tax_label} : ` + this.currency_symbol + '' + parseFloat(this.order_details.data.order_tax).toFixed(2) : '')">
                                                </span>
                                            </span>
                                        </h5>
                                        <p class="mb-0">
                                            <span
                                                v-html="priceFormatter((parseFloat(this.order_details.data.order_tax) + parseFloat(this.order_details.data.packaging_cost)).toFixed(2))"></span>
                                        </p>
                                    </li>
                                    <li class="discount d-flex justify-content-between"
                                        v-if="order_details.data.offer_code != '' && parseFloat(order_details.data.offer_discount) > 0">
                                        <h5>Promo - ({{ order_details.data.offer_code }})</h5>
                                        <p>
                                            -
                                            <span v-html="priceFormatter(order_details.data.offer_discount)"></span>
                                        </p>
                                    </li>
                                    <li class="discount d-flex justify-content-between"
                                        v-if="order_details.data.discount != '' && parseFloat(order_details.data.discount) > 0">
                                        <h5>Discount</h5>
                                        <p>
                                            -
                                            <span v-html="priceFormatter(order_details.data.discount)"></span>
                                        </p>
                                    </li>

                                </ul>
                                <div class="total d-flex justify-content-between">
                                    <h4>Order Amount
                                    </h4>
                                    <p>
                                        <span v-html="priceFormatter(order_details.data.order_amount)"></span>
                                    </p>
                                </div>
                                <ul class="list-unstyled">
                                    <li class="d-flex justify-content-between"
                                        v-if="order_details.data.pay_thru_wallet === 1">
                                        <h5 class="paid_by_wallet">Paid By wallet</h5>
                                        <p>
                                            -
                                            <span class="paid_by_wallet"
                                                v-html="priceFormatter(order_details.data.wallet_amount)"></span>
                                        </p>
                                    </li>
                                    <li class="d-flex justify-content-between"
                                        v-if="order_details.data.pay_thru_wallet === 1">
                                        <h5>Final Amount</h5>
                                        <p>
                                            <span
                                                v-html="priceFormatter(parseFloat((order_details.data.order_amount) - (order_details.data.wallet_amount)).toFixed(2))"></span>
                                        </p>
                                    </li>
                                </ul>
                            </span>
                        </div>
                        <div v-if="order_details.data.offer_remarks != ''" class="total d-flex justify-content-between">
                            <span class="appliedofferremarks">
                                <span style="color:black">Free Item(s) :</span> {{ order_details.data.offer_remarks }}
                            </span>
                        </div>
                        <div class="order-details" style="padding: 3px 0 15px;">
                            <h2 class="section-heading mb-4">Order Details
                            </h2>
                            <ul class="details-listing list-unstyled mt-md-1 mb-md-0">

                                <!-- Remark -->
                                <li v-if="order_details.data.remarks != ''">
                                    <h5>Remark</h5>
                                    <h6 class="mb-0">{{ order_details.data.remarks }}</h6>
                                </li>

                                <!-- Order Number -->
                                <li>
                                    <h5>Order Number</h5>
                                    <h6 class="mb-0">{{ order_details.data.order_no }}</h6>
                                </li>

                                <li>
                                    <h5>Payment</h5>
                                    <h6 class="mb-0"><span
                                            v-if="order_details.data.payment_method_name != null && order_details.data.payment_method_name != ''">{{
                            order_details.data.payment_method_name }}<span
                                                v-if="order_details.data.pay_thru_wallet === 1">,</span></span><span
                                            v-if="order_details.data.pay_thru_wallet === 1">{{ pay_through_wallet
                                            }}</span>
                                    </h6>
                                    <!-- <h6>
                                        <span>
                                            {{ "Online Payment" }}
                                        </span>
                                    </h6> -->
                                </li>

                                <!-- Order Date - Time -->
                                <li>
                                    <h5>Order Date - Time</h5>
                                    <h6 class="mb-0">
                                        {{ order_details.data.order_date }}
                                    </h6>
                                </li>

                                <!-- Delivery Time -->
                                <li
                                    v-if="order_details.data.delivery_type == 1 && order_details.data.delivery_time_type == 0 && order_details.data.delivery_date == '' && order_details.data.from_min != null">
                                    <h5>Delivery Time</h5>
                                    <h6 class="mb-0">+{{ order_details.data.from_min }}{{ order_details.data.to_min !=
                            "0" ?
                            " - " : "" }}{{
                            order_details.data.to_min != "0" ? order_details.data.to_min : "" }} {{
                            order_details.data.delivery_duration == 1 ? "min(s)" :
                                order_details.data.delivery_duration == 2 ?
                                    "hr(s)" : "day(s)" }}
                                    </h6>
                                </li>

                                <!-- Pickup Time -->
                                <li
                                    v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_time_type == 0 && order_details.data.delivery_date == '' && order_details.data.pickup_from != null">
                                    <h5 v-if="order_details.data.iseatery == 0">Pickup Time</h5>
                                    <h5 v-else>Take Away Time</h5>
                                    <h6 class="mb-0">+{{ order_details.data.pickup_from }}{{
                            order_details.data.pickup_to !=
                                "0" ? " - " : ""
                        }}{{
                                order_details.data.pickup_to != "0" ? order_details.data.pickup_to : "" }} {{
                            order_details.data.pickup_duration == 1 ? "min(s)" :
                                order_details.data.pickup_duration == 2 ? "hr(s)" :
                                    "day(s)" }}
                                    </h6>
                                </li>

                                <!-- Delivery Slot -->
                                <li
                                    v-if="order_details.data.delivery_type == 1 && order_details.data.delivery_time_type == 1 && order_details.data.delivery_slot != null && order_details.data.delivery_slot.slot != '' && order_details.data.delivery_slot.to_date != '' && order_details.data.order_status != '5'">
                                    <h5>Delivery Slot</h5>
                                    <h6 class="mb-0">{{ order_details.data.delivery_slot.slot }}</h6>
                                </li>

                                <!-- Pickup Slot -->
                                <li
                                    v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_time_type == 1 && order_details.data.pickup_slot != null && order_details.data.pickup_slot.slot != '' && order_details.data.pickup_slot.to_date != ''">
                                    <h5 v-if="order_details.data.iseatery == 0">Pickup Slot</h5>
                                    <h5 v-else>Take Away Slot</h5>
                                    <h6 class="mb-0">{{ order_details.data.pickup_slot.slot }}</h6>
                                </li>

                                <!-- Delivery/Pickup Date - Time -->
                                <li v-if="order_details.data.delivery_date != ''">
                                    <h5>
                                        <span v-if="order_details.data.delivery_type == 1">Delivery </span>
                                        <span
                                            v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 0">Pickup
                                        </span>
                                        <span
                                            v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 1">Take
                                            Away </span>
                                        Date - Time
                                    </h5>
                                    <h6 class="mb-0">
                                        {{ order_details.data.delivery_date }}
                                    </h6>
                                </li>

                                <!-- Scheduled Delivery -->
                                <li
                                    v-if="order_details.data.delivery_type == 1 && order_details.data.scheduled_delivery_date != '' && order_details.data.order_status != '5'">
                                    <h5>Schedule your Delivery</h5>
                                    <h6 class="mb-0">{{ order_details.data.scheduled_delivery_date }}</h6>
                                </li>

                                <!-- Scheduled Pickup -->
                                <li
                                    v-if="order_details.data.delivery_type == 2 && order_details.data.scheduled_delivery_date != '' && order_details.data.order_status != '5'">
                                    <h5 v-if="order_details.data.iseatery == 0">Schedule your Pickup</h5>
                                    <h5 v-else>Schedule your Take Away</h5>
                                    <h6 class="mb-0">{{ order_details.data.scheduled_delivery_date }}</h6>
                                </li>

                                <!-- Revised Delivery Date - Time -->
                                <li
                                    v-if="order_details.data.delivery_type == 1 && order_details.data.revised_delivery_date != '' && order_details.data.order_status != '5'">
                                    <h5>Revised Delivery Date - Time</h5>
                                    <h6 class="mb-0">{{ order_details.data.revised_delivery_date }}</h6>
                                </li>

                                <!-- Revised Delivery Slot -->
                                <li
                                    v-if="order_details.data.delivery_type == 1 && order_details.data.delivery_time_type == 1 && order_details.data.revised_delivery_slot != null && order_details.data.order_status != '5'">
                                    <h5>Revised Delivery Slot</h5>
                                    <h6 class="mb-0">{{ order_details.data.revised_delivery_slot.slot }}</h6>
                                </li>

                                <!-- Revised Pickup Date - Time -->
                                <li
                                    v-if="order_details.data.delivery_type == 2 && order_details.data.revised_delivery_date != '' && order_details.data.order_status != '5'">
                                    <h5>Revised Pickup Date - Time</h5>
                                    <h6 class="mb-0">{{ order_details.data.revised_delivery_date }}</h6>
                                </li>

                                <!-- Revised Pickup Slot -->
                                <li
                                    v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_time_type == 1 && order_details.data.revised_pickup_slot != null && order_details.data.order_status != '5'">
                                    <h5 v-if="order_details.data.iseatery == 0">Revised Pickup Slot</h5>
                                    <h5 v-if="order_details.data.iseatery == 1">Revised Take Away Slot</h5>
                                    <h6 class="mb-0">{{ order_details.data.revised_pickup_slot.slot }}</h6>
                                </li>

                                <!-- Pickup Code -->
                                <li
                                    v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_code != null && order_details.data.order_status != '5'">
                                    <h5 v-if="order_details.data.iseatery == 0">Pickup Code</h5>
                                    <h5 v-if="order_details.data.iseatery == 1">Take Away Code</h5>
                                    <h6 class="mb-0">{{ order_details.data.pickup_code }}</h6>
                                </li>

                                <!-- Cancellation Number -->
                                <li v-if="order_details.data.cancellation_no != null">
                                    <h5>Cancellation Number</h5>
                                    <h6 class="mb-0">{{ order_details.data.cancellation_no }}</h6>
                                </li>

                                <!-- Cancellation Date -->
                                <li v-if="order_details.data.cancellation_date != null">
                                    <h5>Cancellation Date</h5>
                                    <h6 class="mb-0">{{ order_details.data.cancellation_date }}</h6>
                                </li>

                                <!-- Reject Reason -->
                                <!-- <li
                                    v-if="order_details.data.reject_reason != null && order_details.data.order_status != 4 && order_details.data.order_status != 5">
                                    <h5 v-if="order_details.data.order_status == 6">Reject reason</h5>
                                    <h5 v-if="order_details.data.order_status == 7">Undelivered reason</h5>
                                    <h5 v-if="order_details.data.order_status == 8">Cancel reason</h5>
                                    <h6 class="mb-0">{{ order_details.data.reject_reason }}</h6>
                                </li> -->

                                <!-- Remark For Customer -->
                                <li v-if="order_details.data.remarks_for_customer != null">
                                    <h5>Remark For Customer</h5>
                                    <h6 class="mb-0">{{ order_details.data.remarks_for_customer }}</h6>
                                </li>

                                <!-- Delivery/Pickup Address -->
                                <li>
                                    <h5>
                                        <span v-if="order_details.data.delivery_type == 1">Delivery </span>
                                        <span
                                            v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 0">Pickup
                                        </span>
                                        <span
                                            v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 1">Take
                                            Away </span>
                                        Address
                                    </h5>
                                    <a class="location" href="javascript:void(0);">
                                        <img src="../assets/images/location-icon-gray.png" alt="location-icon.png">
                                        {{ order_details.data.order_address }}
                                    </a>
                                </li>
                                <li v-if="order_details.data.billing_address != null">
                                    <h5>
                                        <span>
                                            Billing Address
                                        </span>
                                    </h5>
                                    <a class="location" href="javascript:void(0);">
                                        <img src="../assets/images/location-icon-gray.png" alt="location-icon.png">
                                        {{ order_details.data.billing_address }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="pay-online pt-2">
                            <h4 style="font-size: 20px;font-family: GothamMedium;margin-bottom: 10px;">Payment Details
                            </h4>
                            <span style="color: red;">
                                Notes: For Credit card purchases, please enter billing information exactly as appears on
                                your credit card account.
                            </span>
                            <form class="payment-form" method="post" data-vv-scope="form2">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="ccname">Name on card</label>
                                        <input type="text" class="form-control"
                                            :class="{ error: errors.has('form2.ccname') }" id="ccname"
                                            placeholder="Name on card" name="ccname" v-model="ccname"
                                            v-validate="'required'">
                                        <span v-show="errors.has('form2.ccname')" class="help is-danger">Name on
                                            card is required</span>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="ccnumber">Card number</label>
                                        <input type="text" class="form-control"
                                            :class="{ error: errors.has('form2.ccnumber') }" id="ccnumber"
                                            placeholder="Card number" name="ccnumber" v-model="ccnumber"
                                            v-validate="'required'" maxlength="19" v-cardformat:formatCardNumber>
                                        <span v-show="errors.has('form2.ccnumber')" class="help is-danger">Card
                                            number is required</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="ccexpiration">Expiration</label>
                                        <input type="text" class="form-control"
                                            :class="{ error: errors.has('form2.ccexpiration') }" id="ccexpiration"
                                            placeholder="MM / YYYY" name="ccexpiration" v-model="ccexpiration"
                                            v-validate="'required'" v-cardformat:formatCardExpiry>
                                        <span v-show="errors.has('form2.ccexpiration')"
                                            class="help is-danger">Expiration is
                                            required</span>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="cccvv">CVV</label>
                                        <input type="text" class="form-control col-md-3"
                                            :class="{ error: errors.has('form2.cccvv') }" id="cccvv" placeholder="CVV"
                                            name="cccvv" v-model="cccvv" v-validate="'required'"
                                            v-cardformat:formatCardCVC>
                                        <span v-show="errors.has('form2.cccvv')" class="help is-danger">Security
                                            code is required</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-if="order_details.data.order_status == 1" class="button text-center">
                            <a @click="Payment()" href="javascript:void(0)"
                                class="btn button-style online-pay hoverEffect">Pay</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loading-for-cartonline" v-if="loading==true">
                <div class="container">
                    <p class="text-center">
                        <img src="../assets/images/loading.gif" />
                    </p>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </Defaultlayout>
</template>
<script>
import Defaultlayout from "../layouts/Defaultlayout";
import { bus } from "../main";

export default {
    name: "CartOnlineOrder",
    components: {
        Defaultlayout,
    },
    data() {
        return {
            orderdetail: {
                order_id: "",
            },
            order_details: "",
            show_price_same_font: "",
            currency_symbol: "",
            delivery_charges: null,
            store_tax_label: "",
            cccvv: '',
            ccname: '',
            ccexpiration: '',
            ccnumber: '',
            order_id: 0,
            user_id: 0,
            loading: false,
            payment_customer: {
                order_id: "",
                user: "",
                card_number: "",
                expiration_date: "",
                card_code: "",
                card_name: ""
            }
        }
    },

    computed: {
        totalAmount: function () {
            var sum = 0;
            if (this.order_details != null) {
                if (this.order_details.status != 0) {
                    this.order_details.data.items.forEach(items => {
                        var item_variants = items.item_variants.filter(
                            function (elem) {
                                if (elem.is_added == "1")
                                    return elem;
                            }
                        );
                        item_variants.forEach(item_variant => {
                            sum += parseFloat(item_variant.discounted_price) * parseFloat(item_variant.quantity);
                        });
                    });
                }
            }
            return parseFloat(sum).toFixed(2);
        },
    },
    mounted() {
        bus.$on("globalsetting", data => {
            if (Object.keys(data).length != 0) {
                this.show_price_same_font = JSON.parse(data).show_price_same_font;
                this.currency_symbol = JSON.parse(data).currency_symbol;
                this.store_tax_label = JSON.parse(data).store_tax_label;
            }
        });
        var base64Text = localStorage.getItem('paymentSlug')
        function base64Decode(base64Text) {
            return atob(base64Text);
        }
        var decodedText = base64Decode(base64Text);
        decodedText = JSON.parse(decodedText);
        this.order_id = decodedText.order_id;
        this.user_id = decodedText.user_id;

        // localStorage.removeItem('mobilenumber');
        // localStorage.removeItem('user');
        // localStorage.removeItem('token');
        // localStorage.removeItem('is_login');
        this.getOrderDetails();

    },
    methods: {
        getOrderDetails() {
            this.loading = true;
            this.orderdetail.order_id = this.order_id;
            this.$store
                .dispatch("getcustomerorderdetail", this.orderdetail)
                .then(response => {
                    this.loading = false;
                    this.order_details = response;
                    if (this.order_details.data.order_status != 1) {
                        console.log(">>>>>>>>>>>>>>>>>>>>>>");
                        this.$router.push("/");
                    }
                });
        },

        priceFormatter(value) {
            if (value) {
                var price = value.toString().split(".")[0];
                var decimal = value.toString().split(".")[1];
                var response = this.currency_symbol + price;
                var decimal_font_size = this.show_price_same_font;
                if (decimal != undefined) {
                    if (decimal_font_size == 1) {
                        response += "<span>." + decimal + "</span>";
                    } else {
                        response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
                    }
                } else {
                    if (decimal_font_size == 1) {
                        response += "<span>.00</span>";
                    } else {
                        response += "<span class='price-decimal'><small>.00</small></span>";
                    }

                }
                return response;
            } else {
                return this.currency_symbol + '0';
            }
        },

        calPriceByQty(price, qty) {
            var total = (parseFloat(price) * parseFloat(qty))
            return total.toFixed(2);
        },

        getAddedItems(items) {
            return items.filter(
                function (elem) {
                    if (elem.is_added == "1") { return elem; }
                }
            );
        },
        Payment() {
            this.loading = true;
            this.$validator.validateAll('form2').then(result => {
                if (result) {
                    this.payment_customer.order_id = this.order_id;
                    this.payment_customer.user_id = this.user_id;
                    this.payment_customer.card_number = this.ccnumber;
                    this.payment_customer.expiration_date = this.ccexpiration;
                    this.payment_customer.card_code = this.cccvv;
                    this.payment_customer.card_name = this.ccname;
                    this.$store
                        .dispatch("paymentforcustomer", this.payment_customer)
                        .then(response => {
                            this.loading = false;
                            if (response.status == 1) {
                                this.$toast.success(response.message);
                                window.location.href = "/";
                            } else {
                                this.$toast.error(response.message);
                            }
                        });
                } else {
                    this.loading = false;
                    this.$toast.error('Please enter the credit card details');
                    return false;
                }
            });
        }
    }
}
</script>
<style>
.online-pay {
    background-color: rgb(65, 35, 94);
    color: rgb(255, 255, 255);
}
</style>